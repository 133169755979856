import React from 'react'
import styled from 'styled-components'
import Btn from './BtnForm'
import color from '../style/color'
import box from '../images/bigger-box.svg'

const MaxUsers = () => {
  return (
    <OuterContainer>
      <Container>
        <Box src={box} alt="cube" />
        <h2>
          Looking for something bigger, <span>we've got something for you</span>
        </h2>
        <div>
          <p>
            While Bob’s Business is for businesses of all sizes, for users over 250, our Essentials Plan could be better suited with custom
            pricing.
          </p>
          <Btn href="https://bobsbusiness.co.uk/contact-us/" type="secondary" target="_blank">
            Contact <span>sales</span>
          </Btn>
        </div>
      </Container>
    </OuterContainer>
  )
}

const Box = styled.img`
  width: 68px;
  position: absolute;
  left: 46px;
  top: -36px;
`

const OuterContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 970px;
`

const Container = styled.div`
  max-width: 970px;
  padding: 50px 70px 40px;
  border-radius: 5px;
  border: 1px solid ${color.primary};
  display: flex;
  position: relative;

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    padding: 30px 20px 30px;
  }

  & h2 {
    font-size: 28px;
    line-height: 38px;
    text-align: left;
    flex: 1;

    @media only screen and (max-width: 850px) {
      font-size: 20px;
      line-height: 30px;
    }

    & span {
      display: block;
      font-weight: 300;
    }
  }

  & > div {
    margin-left: 37px;
    text-align: left;
    flex: 1;

    & a {
      padding: 2px 40px;
      font-weight: bold;

      & span {
        font-weight: 300;
      }
    }

    @media only screen and (max-width: 850px) {
      margin-left: 0;
      margin-top: 20px;
    }

    & p {
      font-size: 15px;
      line-height: 25px;
      margin-bottom: 20px;
    }
  }
`

export default MaxUsers
