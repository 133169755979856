import React from 'react'
import styled from 'styled-components'
import color from '../style/color'
import learning from '../images/better_learning.svg'
import compliance from '../images/complicance.svg'
import readiness from '../images/readiness.svg'

import bank from '../images/logos/bank.png'
import tsa from '../images/logos/tsa_logo.svg'
import dogstrust from '../images/logos/dogstrust.png'
import hmlogo from '../images/logos/hmlogo.png'
import msm from '../images/logos/msm.png'
import simplyhealth from '../images/logos/simplyhealth.png'

const columns = [
  {
    heading: 'Continuous learning is better',
    body: 'A continuous cycle of assessment, education and measurement maximises learning and strengthens your cybersecurity.',
    image: learning
  },
  {
    heading: 'Ensure ongoing compliance',
    body:
      'Maintain audit readiness with continuous training to demonstrate a culture of ethics and compliance and avoid the costs of noncompliance.',
    image: compliance
  },
  {
    heading: 'Ensure new threat readiness',
    body:
      'Cyber threats are constantly evolving. Ensure your employees are up to date on the latest best practices and threat mitigation techniques.',
    image: readiness
  }
]

const images = [
  {
    image: hmlogo,
    alt: 'HM Treasury'
  },
  {
    image: msm,
    alt: 'Money Super Market'
  },
  {
    image: bank,
    alt: 'Lloyds Banking Group'
  },
  {
    image: simplyhealth,
    alt: 'Simplyhealth'
  },
  {
    image: tsa,
    alt: 'The Salvation Army'
  },
  {
    image: dogstrust,
    alt: 'Dogstrust'
  }
]

const ProductDescription = () => {
  return (
    <>
      <OuterContainer>
        <Container>
          <h2>Unlimited access to XX cybersecurity courses</h2>
          <p>Build a cybersecurity culture backed by science and empower your team to become the first line of defense.</p>
        </Container>
        <ColumnContainer>
          {columns.map((column, i) => {
            return (
              <div key={i}>
                <img src={column.image} alt="icon" />
                <h3>{column.heading}</h3>
                <p>{column.body}</p>
              </div>
            )
          })}
        </ColumnContainer>
      </OuterContainer>
      <LogoContainer>
        <p>TRUSTED BY</p>
        <ImageContainer>
          {images.map((image, i) => {
            return (
              <div key={i}>
                <img src={image.image} alt={image.alt} />
              </div>
            )
          })}
        </ImageContainer>
      </LogoContainer>
    </>
  )
}

const ImageContainer = styled.div`
  margin: 0 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & img {
    margin: 0 auto;
    max-width: 100%;
    // width: 100%;
    height: 50px;
  }

  @media only screen and (max-width: 1023px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & div {
      max-width: 100%;
      height: 100%;
      padding: 33px 0;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        border-bottom: 1px solid #e5e5e5;
      }

      &:nth-of-type(1),
      &:nth-of-type(3),
      &:nth-of-type(5) {
        border-right: 1px solid #e5e5e5;
      }
    }
  }
`

const LogoContainer = styled.div`
  border-top: 1px solid #eee;
  padding-top: 54px;
  padding-bottom: 63px;
  text-align: center;

  & p {
    font-size: 15px;
    line-height: 25px;
    color: ${color.text};
    padding-bottom: 30px;
  }
`

const OuterContainer = styled.div`
  margin: 0 7%;
  padding-bottom: 11.7rem;
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    content: '';
    width: 230px;
    height: 1px;
    transform: translateX(-50%);
    background: #e5e5e5;

    @media only screen and (max-width: 850px) {
      background: transparent;
    }
  }
`

const ColumnContainer = styled.div`
  display: flex;

  & div {
    border-top: 1px solid ${color.text};
    padding-top: 36px;
    flex: 1;

    & h3 {
      font-size: 20px;
      font-weight: 500;
      color: ${color.text};
      line-height: 30px;
      padding-bottom: 10px;
    }

    & p {
      color: ${color.text};
      font-size: 16px;
      line-height: 26px;
      font-weight: 300;
    }

    &:not(:last-of-type) {
      margin-right: 30px;
    }

    & img {
      height: 70px;
    }
  }

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    & div {
      &:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 50px;
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 65px;
  justify-content: center;
  align-items: center;

  & h2 {
    color: ${color.text};
    font-size: 5rem;
    line-height: 6rem;
    width: 570px;
    margin-bottom: 22px;
  }

  & p {
    font-weight: 300;
    width: 570px;
    font-size: 16px;
    line-height: 26px;
  }

  @media only screen and (max-width: 850px) {
    & h2,
    & p {
      width: auto;
      margin-bottom: 5rem;
      text-align: left;
    }
    padding: 0 30px;
    padding-top: 50px;
  }
`

export default ProductDescription
