import React, { useState } from 'react'
import styled from 'styled-components'
import { setItem, numberWithCommas, calculateUnitAmount, appendUrlPartnerTracking } from '../util/functions'
import theme from '../style/color'
import UsersTable from './UsersTable'

interface OuterContainerProps {
  sticky: boolean
}

interface ProductInfoCardProps {
  seats: number | string
  setSeats(seats: number | string): void
  productPrices: any
  product: string
  setProduct(product: string): void
}

const ProductInfoCard = ({ seats, productPrices, product, setProduct }: ProductInfoCardProps) => {
  const prices = product === 'year' ? productPrices.yearPrice : product === '3year' ? productPrices.year3Price : productPrices.monthlyPrice

  const calcTotal = (): string => {
    const unitAmount = calculateUnitAmount(prices, seats)
    const arr = numberWithCommas(unitAmount * seats).split('.')
    return (
      <>
        {arr[0]}
        <span>.{arr[1]}</span>
      </>
    )
  }

  const calcMonthly = (): string => {
    const unitAmount = calculateUnitAmount(prices, seats)
    const total = product === 'monthly' ? unitAmount : product === 'year' ? unitAmount / 12 : unitAmount / 36
    const arr = numberWithCommas(total * seats).split('.')
    return (
      <>
        {arr[0]}
        <span>.{arr[1]}</span>
      </>
    )
  }

  const showDiscount = (): string => {
    if (product === 'monthly') {
    } else {
      return <Pill>SAVE {calcSaving()}%</Pill>
    }
  }

  const calcSaving = (): number => {
    const monthPrice = calculateUnitAmount(productPrices.monthlyPrice, seats)
    const cardPrice = calculateUnitAmount(prices, seats) / (product === 'year' ? 12 : 36)

    return Math.ceil((monthPrice / cardPrice) * 100 - 100)
  }

  const perMonth = () => {
    if (seats > 1) {
      const divideBy = product === 'year' ? 12 : product === 'monthly' ? 1 : 36

      return <>(£{(calculateUnitAmount(prices, seats) / divideBy).toFixed(2)}/user)</>
    }
  }

  const totalSection = (newSeats: number) => {
    return (
      <PriceContainer modal={newSeats}>
        <Price>£{calcMonthly()}</Price>
        <section>
          {seats > 1 ? (
            <div>
              <strong>{seats}</strong> users
            </div>
          ) : (
            'per user'
          )}
          , per month {perMonth()}
        </section>
      </PriceContainer>
    )
  }

  return (
    <ContentContainer year={product === 'year'}>
      <InnerContainer>
        <Heading year={product === 'year'}>
          {product === 'monthly' ? 'Monthly Plan' : product === 'year' ? '1 Year Plan' : '3 Year Plan'} {showDiscount()}
        </Heading>
        {totalSection()}
        <section>
          {product === 'monthly' ? `Billed monthly, cancel at anytime` : `Billed as one payment of £`}
          {product !== 'monthly' && calcTotal()}
        </section>
        <Btn
          onClick={() => {
            const newUrl = appendUrlPartnerTracking('/account-details')
            setItem('product', product)
            window.location.href = newUrl
          }}
        >
          <strong>Sign up</strong> now
        </Btn>
      </InnerContainer>
      <LowerContainer>
        <UsersTable users={seats} prices={prices} product={product} />
      </LowerContainer>
    </ContentContainer>
  )
}

export default ProductInfoCard

const Pill = styled.div`
  display: inline-block;
  background: #f7a823;
  color: white;
  font-size: 11px;
  line-height: 17px;
  border-radius: 8px;
  padding: 0 10px;
  margin-left: 10px;
  font-weight: 300;

  @media only screen and (max-width: 1023px) {
    margin-left: 5px;
  }
`

const LowerContainer = styled.div`
  padding: 0 30px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 1023px) {
    padding: 20px;
  }
`

const InnerContainer = styled.div`
  padding: 30px;
  padding-bottom: 37px;
  border-bottom: 1px solid #eee;

  & section {
    margin-top: 15px;
    font-size: 18px;
    line-height: 28px;
    color: ${theme.primary};
    font-weight: 300;
  }

  @media only screen and (max-width: 1199px) {
    padding: 20px;
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
`

const Btn = styled.div`
  border: 1px solid ${theme.red};
  border-radius: 5px;
  color: white;
  background: ${theme.red};
  padding: 12px 35px;
  text-align: center;
  font-size: 16px;
  line-height: 15px;
  cursor: pointer;
  margin-top: 30px;

  transition: background-color 200ms ease, color 200ms ease, background-color 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;

  &:hover,
  &:hover div {
    background-color: ${theme.redHover};
    color: white;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  border-radius: 5px;
  position: relative;
  flex: 1;

  h3 {
    font-family: Roboto;
    font-size: 30px;
    line-height: 35px;
    color: ${theme.secondary};
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 10px;
    padding-top: 10px;
    & span {
      font-weight: 300;
    }
  }
  ${props =>
    props.year &&
    `
    border-color: ${theme.primary};
    &:before {
      position: absolute;
      top: 0;
      left: 23px;
      content: 'Recommended';
      color: ${theme.primary};
      margin-top: -15px;
      background: white;
      padding: 0 10px;
      font-weight: bold;
      font-size: 14px;

      @media only screen and (max-width: 1199px) {
        top: 5px;
        left: 13px;
      }
    }
    `}
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 30px;
  & span {
    font-weight: 300;
  }

  & section {
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-top: 10px;
    color: ${theme.text};
    display: flex;
    align-items: center;

    @media only screen and (max-width: 850px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  ${props => props.modal && `border-bottom: 1px solid #eee;`};
`

const Price = styled.p`
  color: ${theme.primary} !important;
  font-size: 60px;
  font-weight: 600;
  margin: 11px 0;
`

const ProductDesc = styled.div`
  & strong {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 23px;
  }

  & p {
    color: #4a4a4a;
    font-size: 15px;
    font-weight: 300;
    line-height: 23px;
  }

  margin-bottom: 17px;
`

const Heading = styled.p`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 15px;
  position: relative;
  color: ${theme.text};
  display: flex;
  align-items: center;
  ${props => props.year && `color: ${theme.primary};`}
  }
`
