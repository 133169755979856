import React from 'react'
import styled from 'styled-components'

const UsersTable = ({ users, prices, product }) => {
  const getUsers = (price, i) => {
    if (!price.up_to) {
      return `${prices[i - 1].up_to + 1}+`
    }

    if (i === 0) {
      return `1-${price.up_to}`
    }

    return `${prices[i - 1].up_to + 1}-${price.up_to}`
  }

  const formattedPrices = prices.map((price, i) => {
    return {
      max: price.up_to,
      users: getUsers(price, i),
      price: `£${(price.unit_amount / (product === 'monthly' ? 1 : product === 'year' ? 12 : 36) / 100).toFixed(2)}/month`
    }
  })

  const checkForBold = (price, i) => {
    if (!price.max) {
      if (users > formattedPrices[i - 1].max) {
        return true
      }
    }

    if (users <= price.max && users > (formattedPrices[i - 1]?.max || 0)) {
      return true
    }

    return false
  }

  return (
    <Container>
      <Headings>
        <p>USERS</p>
        <p>PRICE PER USER</p>
      </Headings>
      <PriceContainer>
        {formattedPrices.map((price, i) => {
          return (
            <Price key={i} bold={checkForBold(price, i)}>
              <p>{price.users}</p>
              <p>{price.price}</p>
            </Price>
          )
        })}
      </PriceContainer>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 28px;
`

const Headings = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #d9d9d9;

  & p {
    font-size: 11px !important;
    line-height: 13px; !important;
    font-weight: bold !important;
  }
`

const PriceContainer = styled.div`
  padding-top: 5px;
`

const Price = styled.div`
  display: flex;
  justify-content: space-between;

  & p {
    font-size: 13px !important;
    font-weight: 300;
    padding: 2px 0;

    ${props => props.bold && `font-weight: bold !important;`}
  }
`

export default UsersTable
