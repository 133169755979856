import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import IndexLayout from '../layouts'
import { setItem, getItem } from '../util/functions'
import ProductInfoCard from '../components/HomepageCard'
import Loading from '../components/Loading'
import UserSelector from '../components/UserSelector'
import ProductDescription from '../components/ProductDescription'

const qs: Array<IQuestions> = [
  {
    question: 'Can I cancel my subscription at any time?',
    answer: (
      <p>
        Yes. If you are on a monthly or yearly subscription, cancelling your subscription is as easy as turning off automatic renewal.
        You’ll be able to continue using the service up until the point of your subscription’s end date.
      </p>
    )
  },
  {
    question: 'What is a user?',
    answer: (
      <p>
        A ‘user’ is an individual from your organisation who will gain access to the learning management system with the courses and content
        of the administrator’s choice. The organisation’s administrator/owner is also classed as a user.
      </p>
    )
  },
  {
    question: 'Can I add and remove users at any time?',
    answer: (
      <p>
        Yes. Through the billing platform you are able to increase or decrease the number of users on your subscription at any time.
        However, you may not be able to decrease the number of users if you have too many users active on the learning management system.
      </p>
    )
  },
  {
    question: 'What happens when my subscription expires?',
    answer: (
      <p>
        Upon expiry, your account will automatically be deactivated and you will have to reactivate your subscription to regain access to
        the learning management system. Please be aware that any progress made in your previous subscription will not carry over.
      </p>
    )
  }
]

const Homepage = () => {
  const [productPrices, setProductPrices] = useState({})
  const [seats, setSeats] = useState(getItem('seats') || 1)
  const [product, setProduct] = useState(getItem('product') || 'monthly')
  const [processing, toggleProcessing] = useState(true)

  useEffect(() => {
    const url = `${process.env.API_URL}/get-products`

    fetch(url, {
      headers: { Authorization: `Api-Key ${process.env.API_KEY}` }
    })
      .then(resp => resp.json())
      .then(data => {
        setProductPrices(data)
        toggleProcessing(false)
      })
      .catch(err => console.log(err))
    // setProductPrices({ success: true, monthlyPrice: 3.99, yearPrice: 39.99, year3Price: 109.99 })
    // toggleProcessing(false)
  }, [])

  const changeUsers = (e: any) => {
    setSeats(e)
    setItem('seats', e.toString())
  }

  const displayCards = (seats: number) => {
    if (seats != 251) {
      return (
        <CardContainer>
          <ProductInfoCard
            seats={seats}
            setSeats={setSeats}
            productPrices={productPrices}
            product="monthly"
            setProduct={setProduct}
            homepage
          />
          <ProductInfoCard
            seats={seats}
            setSeats={setSeats}
            productPrices={productPrices}
            product="year"
            setProduct={setProduct}
            homepage
          />
          <ProductInfoCard
            seats={seats}
            setSeats={setSeats}
            productPrices={productPrices}
            product="3year"
            setProduct={setProduct}
            homepage
          />
        </CardContainer>
      )
    }
  }

  return processing ? (
    <Loading />
  ) : (
    <IndexLayout currentPage={0} title="Users - Bob's Business" qs={qs}>
      <UserSelector users={seats} changeUsers={changeUsers} />
      {displayCards(seats)}
      <ProductDescription />
    </IndexLayout>
  )
}

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 7%;
  margin-top: 30px;
  margin-bottom: 10rem;

  & > div:not(:last-of-type) {
    margin-right: 30px;

    @media only screen and (max-width: 1023px) {
      margin-right: 10px;
    }
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 0;
    & > div:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
`

export default Homepage
