import React from 'react'
import styled from 'styled-components'
import ReactSlider from 'react-slider'
import color from '../style/color'
import MaxUsers from './MaxUsers'

const UserSelector = ({ users, changeUsers }) => {
  const max = 251

  return (
    <Container>
      <h1>Simple Pricing</h1>
      <p>Unlimited access to XX cybersecurity courses at affordable pricing</p>

      <SliderContainer transform={(users - 1) / 5 + 0.2}>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="thumb"
          trackClassName="track"
          renderThumb={(props, state) => (
            <div {...props}>
              <div>
                <div>
                  {state.valueNow} <span>USER{state.valueNow === 1 ? '' : 'S'}</span>
                </div>
              </div>
            </div>
          )}
          ariaLabel="Number of users"
          defaultValue={users}
          max={max}
          min={1}
          onChange={e => changeUsers(e)}
        />
      </SliderContainer>
      {users != max && <section>You can change the number of users at any time</section>}
      {users == max && <MaxUsers />}
    </Container>
  )
}

const SliderContainer = styled.div`
  max-width: 970px;
  margin: 0 auto;
  margin-top: 75px;

  @media only screen and (max-width: 1199px) {
    max-width: 90%;
  }

  & .horizontal-slider {
    max-width: 970px;
    height: 50px;
    margin: 0 auto;
  }

  & .thumb {
    background: white;
    border: 4px solid ${color.red};
    height: 31px;
    width: 31px;
    border-radius: 50%;
    cursor: pointer;
    bottom: 67%;
    position: relative;
    outline: none;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & > div {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
    }

    & > div > div {
      font-weight: bold;
      font-size: 14px;
      background: ${color.red};
      color: white;
      border-radius: 2px;
      display: flex;
      padding: 1px 12px;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      & span {
        font-weight: 300;
        margin-left: 5px;
        font-size: 14px;
        position: relative;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:after {
          position: absolute;
          content: '';
          bottom: -9px;
          right: 50%;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          ${props => `transform: translate(-${props.transform}%);`}

          border-top: 8px solid ${color.red};
        }
      }
    }
  }

  & .track {
    max-width: 970px;
    height: 3px;
    background: #e2e2e2;
    cursor: pointer;
  }

  & .track-0 {
    background: ${color.red};
    position: relative;
    border-left: 4px solid white;

    &:before {
      height: 21px;
      width: 3px;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background: ${color.red};
      margin-top: -9px;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  & .track-1 {
    position: relative;
    border-right: 4px solid white;

    &:before {
      height: 21px;
      width: 3px;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      background: #e2e2e2;
      margin-top: -9px;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
`

const Container = styled.div`
  text-align: center;
  padding: 0 30px;
  padding-top: 50px;
  padding-bottom: 25px;

  & section {
    text-align: left;
    font-size: 15px;
    color: #4a4a4a;
    font-weight: 300;
    max-width: 970px;
    margin: 0 auto;

    @media only screen and (max-width: 1199px) {
      width: 95%;
    }

    @media only screen and (max-width: 850px) {
      width: 100%;
    }
  }

  & h1 {
    font-size: 6rem;
    line-height: 7rem;
    margin-bottom: 5px;
  }

  & p {
    color: #4a4a4a;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
  }

  @media only screen and (max-width: 850px) {
    padding: 0 30px;
    padding-top: 40px;

    & h1 {
      font-size: 30px;
      line-height: 40px;
      text-align: left;
    }

    & > p:first-of-type {
      text-align: left;
      margin-bottom: 100px;

      @media only screen and (max-width: 850px) {
        margin-bottom: 80px;
      }
    }
  }
`

export default UserSelector
